import React, { FC } from 'react'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Text, Flex } from '@moonpig/launchpad-components'
import { UrlSuggestionItemType } from '../types'
import { LIST_ICON_SIZE_PX } from '../constants'

export type NavigationalSuggestionType = {
  value: string
  url: string
  icon?: React.ElementType
}

const StyledNavigationalSuggestion = styled.li`
  cursor: pointer;
  ${s({ p: 3, color: 'colorTextLabel' })}
  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }

  ${breakpoint('md')} {
    ${s({ p: 3 })}
    button {
      display: none;
    }
    &:hover,
    &:focus {
      button {
        display: block;
      }
    }
  }
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`
const StyledNavText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

type NavigationalSuggestionProps = {
  item: NavigationalSuggestionType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: UrlSuggestionItemType
  }) => Record<string, unknown>
} & React.ComponentPropsWithoutRef<typeof StyledNavigationalSuggestion>

export const NavigationalSuggestion: FC<
  React.PropsWithChildren<NavigationalSuggestionProps>
> = ({ item, itemIndex, getItemProps }) => {
  const Icon = item.icon
  return (
    <StyledNavigationalSuggestion
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'navigational' },
      })}
    >
      {
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <StyledIconWrapper>
              {Icon && (
                <Icon
                  width={LIST_ICON_SIZE_PX}
                  height={LIST_ICON_SIZE_PX}
                  aria-hidden
                  typography={'typeBodyLabel'}
                />
              )}
            </StyledIconWrapper>
          </Flex>
          <StyledNavText typography={'typeBodyText'}>
            {item.value}
          </StyledNavText>
        </Flex>
      }
    </StyledNavigationalSuggestion>
  )
}
