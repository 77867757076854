import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { ThemeInterface } from '@moonpig/launchpad-theme'
import { Flex, Text } from '@moonpig/launchpad-components'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import {
  IconSystemCards,
  IconSystemFlowers,
  IconSystemGift,
  IconSystemSearch,
} from '@moonpig/launchpad-assets'
import { HighlightText } from '../HighlightText'
import { SuggestionItemType } from '../types'
import { LIST_ICON_SIZE_PX } from '../constants'

export type SuggestionType = {
  value: string
  searchKey?: string
  department: { enum: DepartmentsEnum; name: string; title: string }
  resultsCount?: number
  isMainSuggestion?: boolean
}

type StyledSuggestionProps = {
  highlighted?: boolean
  theme: ThemeInterface
}

const StyledSuggestion = styled.li.attrs<StyledSuggestionProps>(
  ({ highlighted, theme }) => ({
    style: {
      backgroundColor: highlighted
        ? theme.colors.colorBackground02
        : theme.colors.colorBackground01,
      borderRadius: '12px',
    },
  }),
)<StyledSuggestionProps>`
  ${s({ p: 3, alignItems: 'center', color: 'colorTextLabel' })}
  display: flex;
  cursor: pointer;

  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`
const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

type SuggestionProps = {
  item: {
    value: string
    searchKey?: string
    department: { name: string; title: string; enum: DepartmentsEnum }
    resultsCount?: number
    isMainSuggestion?: boolean
  }
  itemIndex: number
  value: string
  highlighted?: boolean
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

const getDepartmentIcon = (department: DepartmentsEnum) => {
  switch (department) {
    case DepartmentsEnum.ALL_CARDS:
      return (
        <IconSystemCards width={LIST_ICON_SIZE_PX} height={LIST_ICON_SIZE_PX} />
      )
    case DepartmentsEnum.ALL_GIFTS:
      return (
        <IconSystemGift width={LIST_ICON_SIZE_PX} height={LIST_ICON_SIZE_PX} />
      )
    case DepartmentsEnum.ALL_FLOWERS_AND_PLANTS:
      return (
        <IconSystemFlowers
          width={LIST_ICON_SIZE_PX}
          height={LIST_ICON_SIZE_PX}
        />
      )
    default:
      return (
        <IconSystemSearch
          width={LIST_ICON_SIZE_PX}
          height={LIST_ICON_SIZE_PX}
        />
      )
  }
}

export const Suggestion: FC<React.PropsWithChildren<SuggestionProps>> = ({
  item,
  itemIndex,
  value,
  highlighted,
  getItemProps,
}) => {
  const { department } = item

  return (
    <StyledSuggestion
      highlighted={highlighted}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'suggestion' },
      })}
      data-testid="nav-search-suggestion"
    >
      <StyledIconWrapper data-testid={`${department.enum.toLowerCase()}-icon`}>
        {getDepartmentIcon(department.enum)}
      </StyledIconWrapper>
      <StyledText>
        <HighlightText text={item.value} value={value} />
      </StyledText>
    </StyledSuggestion>
  )
}
