type ABTestingServer = {
  getVariation(campaignName: string): string
  getAllCampaigns(): { [key: string]: string }
}

const campaigns: { [key: string]: string } = {
  'attach-basket-xsell-algorithm': 'Not bucketed',
  'browse-dynamic-hero-validation': 'Not bucketed',
  'client-side-routing': 'Not bucketed',
  'gallery-direct-smile-impact': 'Not bucketed',
  'loyalty-show-mp-plus-selector': 'Not bucketed',
  'shop-on-app-pop-up': 'Not bucketed',
  'show-gift-vouchers': 'Not bucketed',
  'xsell-algorithm': 'Not bucketed',
  'site-xsell-algorithm': 'Not bucketed',
  'browse-tabbed-carousels': 'Not bucketed',
  'pa-aa-test-one': 'Not bucketed',
  'pa-aa-test-two': 'Not bucketed',
  'pa-aa-test-three': 'Not bucketed',
  'browse-contentful-caching': 'Not bucketed',
  'customer-credit-nav': 'Not bucketed',
  'new-pa-aa-test-one': 'Not bucketed',
  'new-pa-aa-test-two': 'Not bucketed',
  'new-pa-aa-test-three': 'Not bucketed',
  'customer-review-and-pay-recipient-tracking': 'Not bucketed',
  'gallery-cta-experiment': 'Not bucketed',
  'browse-navigation-caching': 'Not bucketed',
  'browse-upcoming-occasions-mvt': 'Not bucketed',
  'browse-login-tooltip': 'Not bucketed',
  'loyalty-mp-plus-499': 'Not bucketed',
  'attach-product-video': 'Not bucketed',
  'subscriptions-sign-up-component': 'VariantB',
  'loyalty-plus-in-basket-redesign-v2': 'Not bucketed',
  'editor-cropping': 'Not bucketed',
  'editor-skip-inside-left': 'Not bucketed',
  'attach-first-name-cross-sell-header': 'Not bucketed',
  'editor-sticker-promotion': 'Control',
  'customer-order-conf-redesign': 'Not bucketed',
  'mnpg-price-trial': 'Not bucketed',
  'customer-google-one-tap': 'Not bucketed',
  'checkout-basket-promo-book': 'Not bucketed',
  'explore-editable-card-content': 'Not bucketed',
  'explore-upsell-redesign': 'Not bucketed',
  'explore-nav-redesign-v2': 'enabled',
  'explore-upsell-simplification': 'Not bucketed',
  'explore-show-klarna': 'Not bucketed',
  'explore-basket-gift-cards': 'Not bucketed',
}
export const getABTestingServer = (): ABTestingServer => {
  return {
    getVariation: (campaignName: string) => campaigns[campaignName],
    getAllCampaigns: () => campaigns,
  }
}
